<!-- Footer -->
<footer class="page-footer font-small">

  <!-- Footer Links -->
  <div class="container text-center text-md-left">

    <div class="row">

      <div class="col-md-12">
        <h3>Merit is a simple application to evaluate your team.</h3>
      </div>

      <div class="col-md-12">
        <p>{{ 'HOME.INVITATION_TITLE' | translate }}</p>
        <form class="mt-3" (ngSubmit)="submitForm()" [formGroup]="inviteListForm">
          <div class="form-group">
            <div class="ui-inputgroup">
              <input pInputText
                     type="email"
                     class="w-100"
                     [placeholder]="'HOME.EMAIL' | translate"
                     formControlName="email" />
              <p-button type="submit" [label]="'HOME.SUBSCRIBE' | translate"></p-button>
            </div>
            <div *ngIf="inviteListForm.get('email').invalid && inviteListForm.get('email').touched" class="alert alert-danger">
              <span *ngIf="inviteListForm.get('email').errors.email">
                {{ 'FORMS.VALIDATION.INVALID_EMAIL' | translate }}
              </span>
              <span *ngIf="inviteListForm.get('email').errors.required">
              {{ 'FORMS.VALIDATION.REQUIRED' | translate }}
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>

  <!-- Copyright -->
  <div class="copyright">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-12 text-center">
            {{ appVersion }}
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright -->

</footer>
<!-- Footer -->
