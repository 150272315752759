import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import * as NotificationsActions from './notifications.actions';
import * as AuthActions from '../auth/auth.actions';
import * as NotificationsSelectors from './notifications.selectors';
import { NotificationsService } from '../../services/notifications.service';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../../store';


@Injectable()
export class NotificationsEffects {

  constructor(private actions$: Actions, private notificationSvc: NotificationsService,
              private store: Store<fromApp.AppState>) {
  }

  loadNotificationsAdditional$ = createEffect(() => this.actions$.pipe(
    ofType(NotificationsActions.loadNotifications),
    withLatestFrom(this.store.pipe(select(NotificationsSelectors.selectPaginationNext))),
    exhaustMap(([action, pagination]) => this.notificationSvc.getNotifications(pagination)
      .pipe(
        map(paginationResponse => NotificationsActions.loadNotificationsSuccess({ paginationResponse })),
        catchError(() => EMPTY)
      ))
  ));

  clearNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map(() => NotificationsActions.clearNotifications())
  ));
}
