import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';

import * as AuthActions from './auth.actions';
import { AuthService } from '../../services/auth.service';
import { OauthToken } from '../../interfaces/oauth-token';
import { Router } from '@angular/router';
import { Action, select, Store } from '@ngrx/store';
import * as AuthSelectors from '../auth/auth.selectors';
import * as fromApp from '../../store';
import { UserRoles } from '../../constants/user-roles';
import { QuickTourService } from '../../../core/shared/services/quick-tour.service';

@Injectable()
export class AuthEffects {


  constructor(private actions$: Actions, private authSvc: AuthService,
              private router: Router, private store: Store<fromApp.AppState>,
              private quickTourSvc: QuickTourService) {
  }

  login$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.login),
    exhaustMap((action) => this.authSvc.login({ username: action.username, password: action.password })
      .pipe(
        tap((token: OauthToken) => { localStorage.setItem('token', JSON.stringify(token)); }),
        map((token: OauthToken) => AuthActions.loginSuccess({ token })),
        catchError((error) => of(AuthActions.loginFailed({ errorMessage: error.message })))
      )
    )
  ));

  logout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    tap(() => {
      localStorage.removeItem('token');
      this.router.navigateByUrl('/home');
    })
  ), { dispatch: false });

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginSuccess),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectUserRoles))),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectIsFirstLogin))),
    tap(([[action, userRoles], isFirstLogin]) => {
      if (isFirstLogin) {
        this.quickTourSvc.startTour(userRoles);
      }
      if (userRoles.indexOf(UserRoles.ADMIN) > -1) {
        this.router.navigateByUrl('/admin');
      } else {
        this.router.navigateByUrl('/merit');
      }
    })
  ), { dispatch: false });

  register$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.register),
    tap(() => this.store.dispatch(AuthActions.registerPending())),
    exhaustMap((action) => this.authSvc.register(action.registerFormData, action.captchaResponse)
      .pipe(
        map(() => AuthActions.registerSuccess()),
        catchError(() => of(AuthActions.registerFail()))
      )
    )
  ));

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.refreshToken),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectRefreshToken))),
    exhaustMap(([action, refreshToken]: [Action, string]) => {
      const data = `grant_type=refresh_token&refresh_token=${ encodeURIComponent(refreshToken) }`;
      return this.authSvc.refreshToken(data)
        .pipe(
          tap((token: OauthToken) => { localStorage.setItem('token', JSON.stringify(token)); }),
          map((token: OauthToken) => AuthActions.refreshTokenSuccess({ token })),
          catchError(() => of(AuthActions.logout()))
        );
    })
  ));

  ngrxOnInitEffects(): Action {
    return AuthActions.loginRefresh({ token: JSON.parse(localStorage.getItem('token')) });
  }

}
