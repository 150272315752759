import { createAction, props } from '@ngrx/store';
import { PaginationResponse } from '../../interfaces/pagination-response';

export const loadNotifications = createAction(
  '[Notifications] Load Notifications'
);

export const loadNotificationsSuccess = createAction(
  '[Notifications] Load Notifications Success',
  props<{ paginationResponse: PaginationResponse }>()
);

export const clearNotifications = createAction(
  '[Notifications] Clear Notifications'
);




