import { AfterViewInit, Directive, ElementRef, inject, Renderer2 } from '@angular/core';

@Directive({
  selector: '[truncatedText]',
  standalone: true
})
export class TruncatedTextDirective implements AfterViewInit {

  private elementRef: ElementRef = inject(ElementRef);
  private renderer = inject(Renderer2);

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement.scrollHeight > this.elementRef.nativeElement.offsetHeight) {
      const parent = this.elementRef.nativeElement.parentElement;
      this.renderer.addClass(parent, 'long-message');
    }
  }
}
