import { createAction, props } from '@ngrx/store';
import {Language} from '../../interfaces/language';

export const loadLanguages = createAction(
  '[Language] Load Languages'
);

export const loadLanguagesSuccess = createAction(
  '[Language] Load Languages Success',
  props<{ languages: Language[] }>()
);

export const loadSelectedLanguageLocale = createAction(
  '[Language] Load Selected Language Locale',
  props<{ locale: string }>()
);

export const loadSelectedLanguageLocaleSuccess = createAction(
  '[Language] Load Selected Language Locale Success',
  props<{ locale: string }>()
);




