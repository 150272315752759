import { Injectable, Injector } from '@angular/core';

import { BaseService } from './base.service';
import { environment } from '../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService extends BaseService {

  constructor(injector: Injector) {
    super(injector);
    this.path = environment.host + environment.apiPath;
  }
}
