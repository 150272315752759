<p-dialog [header]="'NOTIFICATIONS.CREATE_MODAL_TITLE' | translate"
          [draggable]="false"
          [closable]="false"
          [visible]="visible"
          [style]="{width: '50vw'}">
  <div class="d-flex">
    <form [formGroup]="notificationForm">
      <div class="form-group">
        <label for="title" class="d-block">{{ 'NOTIFICATIONS_CREATE.TITLE_LABEL' | translate }}</label>
        <input id="title"
               pInputText
               class="w-100"
               formControlName="title"/>
        <div *ngIf="notificationForm.get('title').invalid && notificationForm.get('title').touched" class="alert alert-danger">
          <span *ngIf="notificationForm.get('title').errors.required">
            {{ 'FORMS.VALIDATION.REQUIRED' | translate }}
          </span>
        </div>
      </div>
      <div class="form-group">
        <label for="notification" class="d-block">{{ 'NOTIFICATIONS_CREATE.MESSAGE' | translate }}</label>
        <textarea id="notification"
                  pInputTextarea
                  rows="5"
                  class="w-100"
                  formControlName="message"
                  style="margin-bottom: -5px">
              </textarea>
        <div *ngIf="notificationForm.get('message').invalid && notificationForm.get('message').touched"
             class="alert alert-danger">
                  <span *ngIf="notificationForm.get('message').errors.required">
                    {{ 'FORMS.VALIDATION.REQUIRED' | translate }}
                  </span>
        </div>
      </div>
      <div class="form-group">
        <label for="user-roles" class="d-block mt-3">{{ 'NOTIFICATIONS_CREATE.GROUPS' | translate }}</label>
        <p-multiSelect id="user-roles"
                       [appendTo]="'body'"
                       [options]="userRoles$ | async"
                       formControlName="roles"
                       optionLabel="userRole">
        </p-multiSelect>
        <div *ngIf="notificationForm.get('roles').invalid && notificationForm.get('roles').touched" class="alert alert-danger">
                  <span *ngIf="notificationForm.get('roles').errors.required">
                    {{ 'FORMS.VALIDATION.REQUIRED' | translate }}
                  </span>
        </div>
      </div>
    </form>
  </div>

  <p-footer>
    <button pButton
            class="confirm-button"
            icon="fa fa-check"
            (click)="onConfirm()"
            [label]="'COMMON.SUBMIT' | translate">
    </button>
    <button pButton
            class="gray-cancel-button m-0"
            icon="fa fa-close"
            (click)="onCancel()"
            [label]="'COMMON.CANCEL' | translate">
    </button>
  </p-footer>
</p-dialog>
