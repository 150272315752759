import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateNotificationService } from '../../services/create-notification.service';
import { tap } from 'rxjs/operators';
import { ModalCreateNotificationComponent } from './modal-create-notification/modal-create-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, DatePipe, TitleCasePipe, NgIf, NgClass } from '@angular/common';
import { TableModule, TableLazyLoadEvent } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { TruncatedTextDirective } from './truncated-text.directive';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalCreateNotificationComponent,
    TranslateModule,
    AsyncPipe,
    TableModule,
    DatePipe,
    TitleCasePipe,
    ButtonModule,
    NgIf,
    TruncatedTextDirective,
    NgClass
  ]
})
export class NotificationsListComponent implements OnInit {

  private notificationSvc = inject(CreateNotificationService);
  notificationList$: Observable<any> = this.notificationSvc.notificationList$()
    .pipe(tap(() => this.dialogVisible = false));
  dialogVisible = false;
  textCollapsed = true;

  ngOnInit(): void {
    this.onLazyLoad();
  }

  openCreateDialog(): void {
    this.dialogVisible = true;
  }

  onCreateModalClose(): void {
    this.dialogVisible = false;
  }

  onLazyLoad(e?: TableLazyLoadEvent): void {
    this.notificationSvc.getNotifications(e);
  }

  createNotification(notification: { rolename: string[], message: string, title: string }): void {
    this.notificationSvc.createNotification(notification);
  }

  toggleText(): void {
    this.textCollapsed = !this.textCollapsed;
  }
}
