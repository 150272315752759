<div class="invite-list-container">
  <p-table [value]="inviteList" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th width="5%">#</th>
        <th>E-mail</th>
        <th width="15%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-invitation let-i="rowIndex+1">
      <tr>
        <td>{{ i }}</td>
        <td>{{ invitation.email }}</td>
        <td><button pButton [label]="'ADMIN.SEND_INVITATION' | translate"></button></td>
      </tr>
    </ng-template>
  </p-table>
</div>

