import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

/**
 * This service is used to display messages to user in a toast for the whole application.
 */
@Injectable({
  providedIn: 'root'
})
export class MerriteMessageService {

  /**
   * Do not display messages with these codes on these urls
   */
  private excludedCodesOnUrls: { [key: string]: string[] } = {
    '\/reset-password': ['INVALID_TOKEN']
  };

  /**
   * Add custom properties for message codes, e.g. severity, stickiness, etc.
   */
  private customMessageProperties = {
    ACCOUNT_LOCKED: {
      severity: Severity.WARN,
      sticky: true
    },
    COPIED: {
      severity: Severity.SUCCESS
    },
    USER_RATED: {
      severity: Severity.SUCCESS
    },
    NOTIFICATION_CREATED: {
      severity: Severity.SUCCESS
    },
    EMAIL_ADDED_TO_INVITE_LIST: {
      severity: Severity.SUCCESS
    },
    SETTINGS_UPDATED: {
      severity: Severity.SUCCESS
    },
    CONFIGURATION_UPDATED: {
      severity: Severity.SUCCESS
    },
    MEMBER_CONFIRMED: {
      severity: Severity.SUCCESS
    },
    MEMBER_REJECTED: {
      severity: Severity.SUCCESS
    },
    OVERLAPPING_PERIODS: {
      severity: Severity.WARN
    }
  };

  constructor(private messageSvc: MessageService,
              private translateSvc: TranslateService,
              private router: Router) {
    this.clearMessagesOnRouteChange();
  }

  private clearMessagesOnRouteChange(): void {
    // Clear all messages on route change in case there are any sticky messages
    this.router.events.forEach(() => {
      this.messageSvc.clear();
    });
  }

  publish(code: string, messageParams?: { [key: string]: string }) {
    if (this.isExcludedCodeOnUrl(code)) {
      return;
    }

    const severity = this.customMessageProperties[code]?.severity || Severity.ERROR;
    const sticky = this.customMessageProperties[code]?.sticky || null;
    const summary = this.translateSummary(code, messageParams) || this.translateSvc.instant('MESSAGE.GENERIC_ERROR.SUMMARY');
    const detail = this.translateDetail(code, messageParams) || this.translateSvc.instant('MESSAGE.GENERIC_ERROR.DETAIL');

    // Clear all messages before publishing a new one
    this.messageSvc.clear();
    this.messageSvc.add({severity, summary, detail, sticky});
  }

  private translateSummary(messageCode: string, messageParams?: { [key: string]: string }): string {
    const translationKey = 'MESSAGE.' + messageCode + '.SUMMARY';
    const translation = this.translateSvc.instant('MESSAGE.' + messageCode + '.SUMMARY', messageParams);
    return translation !== translationKey ? translation : null;
  }

  private translateDetail(messageCode: string, messageParams?: { [key: string]: string }): string {
    const translationKey = 'MESSAGE.' + messageCode + '.DETAIL';
    const translation = this.translateSvc.instant('MESSAGE.' + messageCode + '.DETAIL', messageParams);
    return translation !== translationKey ? translation : null;
  }

  private isExcludedCodeOnUrl(code: string): boolean {
    for (const urlPattern of Object.keys(this.excludedCodesOnUrls)) {
      if (new RegExp(urlPattern).test(this.router.url)) {
        return this.excludedCodesOnUrls[urlPattern].includes(code);
      }
    }
  }
}

enum Severity {
  WARN = 'warn',
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success'
}
