import { Action, createReducer, on } from '@ngrx/store';
import * as UserDataActions from './user-data.actions';
import { UserData } from '../../interfaces/user-data';
import { UserRoles } from '../../constants/user-roles';

export const userDataFeatureKey = 'userData';

export interface State {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  team: string;
  organizationName: string;
  role: UserRoles;
  settings: any;
}

export const initialState: State = {
  userId: null,
  firstName: null,
  lastName: null,
  email: null,
  team: null,
  organizationName: null,
  role: null,
  settings: null
};

const userDataReducer = createReducer(
  initialState,

  on(UserDataActions.loadUserData, state => state),
  on(UserDataActions.loadUserDataSuccess, (state, { userData }) => loadUserDataSuccessHandler(state, userData)),
  on(UserDataActions.clearUserData, (state) => clearUserDataHandler(state)),
  on(UserDataActions.loadUserSettingsSuccess, (state, { settings }) => loadUserSettingsSuccessHandler(state, settings))

);

export function reducer(state: State | undefined, action: Action) {
  return userDataReducer(state, action);
}

export function loadUserSettingsSuccessHandler(state: State, settings): State {
  return { ...state, settings };
}

export function loadUserDataSuccessHandler(state: State, userData: UserData): State {
  return userData ? {
    ...state,
    userId: userData.userId,
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    team: userData.team,
    organizationName: userData.organizationName,
    role: userData.role
  } : { ...initialState };
}

export function clearUserDataHandler(state: State): State {
  return { ...initialState };
}
