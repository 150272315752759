<div *ngIf="isLoading$ | async" class="d-flex align-items-center justify-content-center h-100">
  <span class="spinner-border" role="status"></span>
</div>

<div *ngIf="!(isLoading$ | async)" class="dashboard-panel ui-g h-100">
  <div class="ui-g-12">
    <div class="card h-100">
      <div class="card-body">
        <h4 class="card-title">{{ 'ADMIN.APPLICATION_SETTINGS' | translate }}</h4>
        <div class="d-flex align-items-center justify-content-between mt-5">
          <span>{{ 'ADMIN.REGISTRATION_ENABLED' | translate }}</span>
          <p-inputSwitch [(ngModel)]="registrationActive" [ngStyle]="{ 'height': '25px' }"></p-inputSwitch>
        </div>      </div>
      <div class="card-footer">
        <button pButton [label]="'COMMON.UPDATE' | translate" (click)="update()"></button>
      </div>
    </div>
  </div>
</div>

