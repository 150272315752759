import { EventEmitter, Input, Output, Directive } from '@angular/core';

@Directive()
export abstract class ModalComponent  {

  @Input() visible = false;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() confirm: EventEmitter<any> = new EventEmitter();

  constructor() { }

}
