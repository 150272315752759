import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import * as AppSettingsActions from '../store/app-settings/app-settings.actions';
import {select, Store} from '@ngrx/store';
import * as AppSettingsSelectors from '../store/app-settings/app-settings.selectors';
import * as fromApp from '../store';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private apiSvc: ApiService, private store: Store<fromApp.AppState>) {}

  getSettings() {
    return this.apiSvc.get('/app-settings');
  }

  updateSettings(newAppSettings) {
    return this.apiSvc.post('/app-settings/update', newAppSettings);
  }

  load(): Promise<any> {
    return new Promise((resolve) => {
      this.store.dispatch(AppSettingsActions.loadAppSettings());
      this.store.pipe(select(AppSettingsSelectors.selectRegistrationActive))
        .subscribe(() => {
          resolve(true);
        });
    });
  }
}
