import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, concatMap, exhaustMap, map, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import * as AppSettingsActions from './app-settings.actions';
import { AppService } from '../../services/app.service';
import { AppSettings } from '../../interfaces/app-settings';
import { MerriteMessageService } from '../../../core/shared/services/merrite-message.service';


@Injectable()
export class AppSettingsEffects {

  constructor(private actions$: Actions, private appSvc: AppService,
              private messageSvc: MerriteMessageService) {
  }

  loadAppSettings$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.loadAppSettings),
    concatMap(() => this.appSvc.getSettings()
      .pipe(
        map((appSettings: AppSettings) => AppSettingsActions.loadAppSettingsSuccess({appSettings})),
        catchError(() => EMPTY)
      )
    )
  ));

  updateAppSettings$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.updateAppSettings),
    exhaustMap((action) => this.appSvc.updateSettings(action.newAppSettings)
      .pipe(
        map(() => AppSettingsActions.updateAppSettingsSuccess())
      )
    )
  ));


  updateAppSettingsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AppSettingsActions.updateAppSettingsSuccess),
    tap(() => {
      this.messageSvc.publish('SETTINGS_UPDATED');
    })
  ), { dispatch: false });

}
