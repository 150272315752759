import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserRoles } from '../../shared/constants/user-roles';
import { JoyrideService } from 'ngx-joyride';
import { QuickTourService } from '../shared/services/quick-tour.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-quick-tour',
  templateUrl: './quick-tour.component.html',
  styleUrls: ['./quick-tour.component.scss']
})
export class QuickTourComponent implements OnInit, OnDestroy {
  startQuickTour$: Observable<string[]> = this.quickTourService.startQuickTour$;

  private destroyed$ = new Subject();

  constructor(private joyrideService: JoyrideService,
              private quickTourService: QuickTourService) {
  }

  ngOnInit(): void {
    this.startQuickTour$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((userRoles: UserRoles[]) => {
        this.startTour(userRoles);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  public startTour(userRoles: UserRoles[]): void {
    if (userRoles.indexOf(UserRoles.OWNER) > -1) {
      this.joyrideService.startTour({steps: ['welcome', 'owner-organization-settings', 'owner-periods', 'owner-members', 'owner-end']});
    } else if (userRoles.indexOf(UserRoles.USER) > -1) {
      this.joyrideService.startTour({steps: ['welcome', 'user-feedback', 'user-friendly-advice', 'user-end']});
    } else if (userRoles.indexOf(UserRoles.MANAGER) > -1) {
      this.joyrideService.startTour({
        steps: ['welcome', 'manager-organization-settings', 'owner-periods', 'manager-members',
          'manager-feedback', 'user-friendly-advice', 'owner-end']
      });
    }
  }
}
