import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from '../auth/auth.reducer';

export const selectFeature = createFeatureSelector<fromAuth.State>(fromAuth.authFeatureKey);

export const selectIsLoggedIn = createSelector(
  selectFeature,
  (state: fromAuth.State) => state.isLoggedIn
);

export const selectIsFirstLogin = createSelector(
  selectFeature,
  (state: fromAuth.State) => state.accessTokenData ? state.accessTokenData.firstLogin : false
);

export const selectUserRoles = createSelector(
  selectFeature,
  (state: fromAuth.State) => state.accessTokenData ? state.accessTokenData.roles : []
);

export const selectRegistrationRequestStatus = createSelector(
  selectFeature,
  (state: fromAuth.State) => state.registrationRequestStatus
);

export const selectUserEmail = createSelector(
  selectFeature,
  (state: fromAuth.State) => state.accessTokenData ? state.accessTokenData.sub : null
);

export const selectRefreshToken = createSelector(
  selectFeature,
  (state: fromAuth.State) => state.refreshToken
);


export const refreshingToken = createSelector(
  selectFeature,
  (state: fromAuth.State) => {
    return {
      refreshing: state.refreshingToken,
      refreshed: state.tokenRefreshed
    }
  }
);

export interface TokenRefreshing {
  refreshing: boolean;
  refreshed: boolean;
}
