import {Component, OnDestroy, OnInit} from '@angular/core';
import {Language} from '../../../shared/interfaces/language';
import {select, Store} from '@ngrx/store';
import * as fromApp from '../../../shared/store';
import * as languageActions from '../../../shared/store/language/language.actions';
import * as languageSelectors from '../../../shared/store/language/language.selectors';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-select-language',
  templateUrl: './select-language.component.html',
  styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent implements OnInit, OnDestroy {

  languages$: Observable<Language[]> = this.store.pipe(select(languageSelectors.selectLanguages));
  selectedLanguage: Language;

  destroyed$: Subject<any> = new Subject();

  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.store.dispatch(languageActions.loadLanguages());
    this.store
      .pipe(
        takeUntil(this.destroyed$),
        select(languageSelectors.selectSelectedLanguage)
      )
      .subscribe((language: Language) => {
        this.selectedLanguage = language;
      });
  }

  ngOnDestroy() {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  onLanguageSelect(e) {
    this.store.dispatch(languageActions.loadSelectedLanguageLocale({ locale: e.value.locale }));
  }

}
