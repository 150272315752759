import { Action, createReducer, on } from '@ngrx/store';
import * as NotificationsActions from './notifications.actions';
import { Notification } from '../../interfaces/notification';
import { PaginationResponse } from '../../interfaces/pagination-response';

export const notificationsFeatureKey = 'notifications';

export interface State {
  notifications: Notification[];
  page: number; // last loaded page
  pagesAvailable: number,
  resultsPerPage: number;
  order: string;
  orderBy: string;
  initialLoad: boolean;
}

export const initialState = {
  // additional entity state properties
  notifications: [],
  page: 0,
  pagesAvailable: null,
  resultsPerPage: 20,
  order: 'DESC',
  orderBy: 'date_created',
  initialLoad: false
};

const notificationsReducer = createReducer(
  initialState,

  on(NotificationsActions.loadNotifications, state => state),
  on(NotificationsActions.loadNotificationsSuccess,
    (state, { paginationResponse }) => loadNotificationsSuccessHandler(state, paginationResponse)),
  on(NotificationsActions.clearNotifications, () => clearNotificationsHandler())

);

export function reducer(state: State | undefined, action: Action) {
  return notificationsReducer(state, action);
}

function loadNotificationsSuccessHandler(state: State, paginationResponse: PaginationResponse): State {
  return {
    ...state,
    notifications: paginationResponse.content ? state.notifications.concat(paginationResponse.content) : state.notifications,
    page: paginationResponse.content ? paginationResponse.pageNumber : state.page,
    pagesAvailable: paginationResponse.pagesAvailable,
    initialLoad: true
  };
}

function clearNotificationsHandler(): State {
  return { ...initialState };
}

