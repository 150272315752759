import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../store';
import * as AuthSelectors from '../store/auth/auth.selectors';
import * as LanguageSelectors from '../store/language/language.selectors';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class MenuService implements OnDestroy {
  // menu data by role
  menu: any;

  // final multiple role menu for current user
  private userMenu: BehaviorSubject<MenuItem[]> = new BehaviorSubject(null);
  userMenu$: Observable<MenuItem[]> = this.userMenu.asObservable();

  private destroyed$: Subject<any> = new Subject();

  constructor(private store: Store<fromApp.AppState>, private translateSvc: TranslateService) {
    this.store
      .pipe(
        takeUntil(this.destroyed$),
        select(AuthSelectors.selectUserRoles)
      )
      .subscribe(userRoles => {
        this.handleRoleChange(userRoles);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  handleRoleChange(userRoles) {
    userRoles.length > 0 ? this.buildMenu(userRoles) : this.clearMenu();
  }

  buildMenu(userRoles) {
    this.store
      .pipe(
        takeUntil(this.destroyed$),
        select(LanguageSelectors.selectSelectedLanguageLocale),
        switchMap((locale) => this.translateSvc.getTranslation(locale))
      ).subscribe(() => {
        // assure translation file is loaded before calling instant
        this.defineAllMenus();
        this.userMenu.next(this.menu[userRoles[0].toString().toLowerCase()]);

        // TODO: menu if user has multiple user roles
      });
  }

  clearMenu() {
    this.userMenu.next([]);
  }

  defineAllMenus(): void {
    this.menu = {
      admin: [
        {
          routerLink: '/admin',
          label: 'Admin'
        },
        {
          routerLink: '/notifications',
          label: this.translateSvc.instant('HEADER.LINK.NOTIFICATIONS')
        }
      ],
      owner: [
        {
          routerLink: '/merit',
          label: this.translateSvc.instant('HEADER.LINK.DASHBOARD')
        },
        {
          label: this.translateSvc.instant('HEADER.LINK.ORGANIZATION'),
          items: [
            {
              label: this.translateSvc.instant('HEADER.LINK.SETTINGS'),
              items: [
                {
                  routerLink: '/merit/owner/settings',
                  label: this.translateSvc.instant('HEADER.LINK.ORG')
                },
                {
                  routerLink: '/merit/owner/periods',
                  label: this.translateSvc.instant('HEADER.LINK.PERIOD_SETUP')
                },
              ]
            },
            {
              routerLink: '/merit/owner/members',
              label: this.translateSvc.instant('HEADER.LINK.MEMBERS')
            }
          ]
        },
        {
          label: this.translateSvc.instant('HEADER.LINK.REPORTS'),
          items: [
            {
              label: 'Transactions',
              routerLink: '/merit/owner/report/transactions',
            },
            {
              label: 'Payouts',
              routerLink: '/merit/owner/report/payouts',
            }
          ]
        },
        {
          routerLink: '/notifications',
          label: this.translateSvc.instant('HEADER.LINK.NOTIFICATIONS')
        }
      ],
      manager: [
        {
          routerLink: '/merit',
          label: 'Dashboard'
        },
        {
          label: this.translateSvc.instant('HEADER.LINK.ORGANIZATION'),
          items: [
            {
              label: this.translateSvc.instant('HEADER.LINK.SETTINGS'),
              items: [
                {
                  routerLink: '/merit/owner/settings',
                  label: this.translateSvc.instant('HEADER.LINK.ORG')
                },
                {
                  routerLink: '/merit/owner/periods',
                  label: this.translateSvc.instant('HEADER.LINK.PERIOD_SETUP')
                },
              ]
            },
            {
              routerLink: '/merit/owner/members',
              label: this.translateSvc.instant('HEADER.LINK.MEMBERS')
            }
          ]
        },
        {
          routerLink: '/merit/rate-team',
          label: this.translateSvc.instant('HEADER.LINK.RATE_TEAM')
        },
        {
          routerLink: '/notifications',
          label: this.translateSvc.instant('HEADER.LINK.NOTIFICATIONS')
        },
        {
          label: this.translateSvc.instant('HEADER.LINK.REPORTS'),
          items: [
            {
              label: 'Owner Reports',
              items: [
                {
                  label: 'Transactions',
                  routerLink: '/merit/owner/report/transactions',
                },
                {
                  label: 'Payouts',
                  routerLink: '/merit/owner/report/payouts',
                }
              ]
            },
            {
              label: 'My Report',
              routerLink: '/merit/user/report/personal'
            }
          ]
        }
      ],
      user: [
        {
          routerLink: '/merit',
          label: 'Dashboard'
        },
        {
          routerLink: '/merit/rate-team',
          label: this.translateSvc.instant('HEADER.LINK.RATE_TEAM')
        },
        {
          routerLink: '/merit/user/report/personal',
          label: this.translateSvc.instant('HEADER.LINK.PERSONAL_REPORT')
        }
      ]
    };
  }
}
