import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as AuthSelectors from '../store/auth/auth.selectors';
import { tap } from 'rxjs/operators';

export const AuthGuard: CanActivateFn = () => {
  const router = inject(Router);

  return inject(Store)
    .pipe(
      select(AuthSelectors.selectIsLoggedIn),
      tap((loggedIn: boolean) => {
        if (!loggedIn) {
          router.navigateByUrl('/home');
        }
      })
    );
};
