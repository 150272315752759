import { Injectable } from '@angular/core';
import { HeaderType } from '../enums/header-type';
import { Observable, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { BackgroundType } from '../enums/background-type';

/**
 * Service to set type of page content, e.g. displayed header content type
 * and background animation content type at the bottom of page
 */
@Injectable({
  providedIn: 'root'
})
export class PageContentTypeService {
  private headerType: Subject<HeaderType> = new Subject();
  headerType$: Observable<HeaderType> = this.headerType.asObservable();

  private bottomBackgroundAnimationType: Subject<BackgroundType> = new Subject();
  bottomBackgroundAnimationType$: Observable<BackgroundType> = this.bottomBackgroundAnimationType.asObservable();

  constructor(private router: Router) {
    this.subToRouterEvents();
  }

  private subToRouterEvents(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects.includes('home')) {
          this.headerType.next(HeaderType.Landing);
        } else if (event.urlAfterRedirects.includes('admin')) {
          this.headerType.next(HeaderType.Admin);
        } else {
          this.headerType.next(HeaderType.Regular);
        }
      }
    });
  }

  setBottomBackgroundAnimationType(backgroundType: BackgroundType): void {
    this.bottomBackgroundAnimationType.next(backgroundType);
  }
}
