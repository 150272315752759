import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromApp from '../../shared/store';
import * as AuthActions from '../../shared/store/auth/auth.actions';
import * as AuthSelectors from '../../shared/store/auth/auth.selectors';
import * as UserDataSelectors from '../../shared/store/user-data/user-data.selectors';
import { Observable } from 'rxjs';
import { MenuService } from '../../shared/services/menu.service';
import { UserData } from '../../shared/interfaces/user-data';
import { MenuItem } from 'primeng/api';
import { QuickTourService } from '../shared/services/quick-tour.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  isLoggedIn$: Observable<boolean> = this.store.pipe(select(AuthSelectors.selectIsLoggedIn));
  userData$: Observable<UserData> = this.store.pipe(select(UserDataSelectors.selectUserDataState));
  menuItems$: Observable<MenuItem[]> = this.menuSvc.userMenu$;

  constructor(private store: Store<fromApp.AppState>,
              private menuSvc: MenuService,
              private quickTourSvc: QuickTourService) {
  }

  logout() {
    this.store.dispatch(AuthActions.logout());
  }

  showQuickTour() {
    this.quickTourSvc.startTourForAuthenticatedUser();
  }
}

