import { Component, OnDestroy, OnInit } from '@angular/core';
import { InviteListService } from '../../shared/services/invite-list.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-invite-list',
  templateUrl: './invite-list.component.html',
  styleUrls: ['./invite-list.component.scss']
})
export class InviteListComponent implements OnInit, OnDestroy {

  inviteList: { email: string }[] = [];

  destroyed$: Subject<any> = new Subject();

  constructor(private inviteListSvc: InviteListService) { }

  ngOnInit() {
    this.getInviteList();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  getInviteList() {
    this.inviteListSvc.getInviteList()
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((inviteList: { email: string }[]) => {
        this.inviteList = inviteList;
      });
  }

}
