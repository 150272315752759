import { Component, OnDestroy, OnInit } from '@angular/core';
import {AppService} from '../../shared/services/app.service';
import {select, Store} from '@ngrx/store';
import * as fromApp from '../../shared/store';
import * as AppSettingsActions from '../../shared/store/app-settings/app-settings.actions';
import {AppSettings} from '../../shared/interfaces/app-settings';
import * as AppSettingsSelectors from '../../shared/store/app-settings/app-settings.selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit, OnDestroy {

  registrationActive = false;
  isLoading$: Observable<boolean> = this.store.pipe(select(AppSettingsSelectors.selectAppSettingsLoading));

  destroyed$: Subject<any> = new Subject();

  constructor(private appSvc: AppService, private store: Store<fromApp.AppState>) { }

  ngOnInit() {
    this.store.pipe(select(AppSettingsSelectors.selectFeature))
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((appSettings: AppSettings) => {
        this.registrationActive = appSettings.registrationActive;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  update() {
    const newAppSettings = {
      registrationActive: this.registrationActive
    };

    this.store.dispatch(AppSettingsActions.updateAppSettings({ newAppSettings }));
  }

}
