import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { select, Store } from '@ngrx/store';
import * as AuthSelectors from '../store/auth/auth.selectors';
import { map } from 'rxjs/operators';
import { UserRoles } from '../constants/user-roles';
import * as fromApp from '../store';
import { MerriteMessageService } from '../../core/shared/services/merrite-message.service';

@Injectable({
  providedIn: 'root'
})
export class CreateNotificationService {

  private notificationListSub = new BehaviorSubject<any>([]);
  private apiSvc = inject(ApiService);
  private messageSvc = inject(MerriteMessageService);
  private store = inject(Store<fromApp.AppState>);

  roles$(): Observable<any> {
    return this.store
      .pipe(
        select(AuthSelectors.selectUserRoles),
        map((roles: UserRoles[]) => {
          const userRoles = [];

          for (const userRole in UserRoles) {
            if (roles.indexOf(UserRoles.ADMIN) > -1) {
              userRoles.push({ userRole });
            } else if (roles.indexOf(UserRoles.OWNER) > -1 && userRole !== UserRoles.ADMIN && userRole !== UserRoles.OWNER) {
              userRoles.push({ userRole });
            } else if (roles.indexOf(UserRoles.MANAGER) > -1 && userRole !== UserRoles.ADMIN) {
              userRoles.push({ userRole });
            }
          }

          return userRoles;
        })
      );
  }

  notificationList$(): Observable<any> {
    return this.notificationListSub.asObservable();
  }

  createNotification(notification: { rolename: string[], message: string, title: string }) {
    return this.apiSvc.post('/owner/notifications/create', notification)
      .subscribe(() => {
        this.messageSvc.publish('NOTIFICATION_CREATED');
        this.getNotifications();
      });
  }

  getNotifications(e?: any) {
    const reqObj = {
      page: e ? e.first / e.rows + 1 : 1,
      resultsPerPage: 10,
      orderBy: e ? (e.sortField ? e.sortField.toUpperCase() : 'DATE_CREATED') : 'DATE_CREATED',
      order: e ? (e.sortOrder === -1 ? 'DESC' : 'ASC') : 'DESC'
    };

    return this.apiSvc.post('/owner/get-notifications', reqObj)
      .subscribe((res) => this.notificationListSub.next(res));
  }
}
