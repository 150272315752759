import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

export function validateForm(form: FormGroup) {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    }
    else if (control instanceof FormGroup) {
      validateForm(control);
    }
  });
}

export class CustomValidators {

  static minCharsInNames = 3;
  static maxCharsInNames = 40;

  static names(c: AbstractControl) {
    const nameRegex = /^[A-Za-zÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ\s]*$/;
    if (!nameRegex.test(c.value)) {
      return { invalidName: true };
    }

    return null;
  }

  static password(c: AbstractControl) {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,64}$/;

    if (!passwordRegex.test(c.value)) {
      return { invalidPassword: true };
    }

    return null;
  }

  static validateEmail(c: AbstractControl): ValidationErrors | null {
    if (c.value) {
      const valid = validationRegex.email.test(c.value);
      return valid ? null : {email: 'forms.validation.INVALID_EMAIL'};
    }
    return null;
  }

  static setPasswordControl(c: AbstractControl) {
    if (c.parent) {
      return c.value === c.parent.controls['confirmPassword'].value ?
        c.parent.controls['confirmPassword'].setErrors(null) :
        c.parent.controls['confirmPassword'].setErrors({ passwordsDoNotMatch: true });
    }

    return null;
  }

  static comparePasswords(c: AbstractControl) {
    if (c.parent) {
      return c.value === c.parent.controls['password'].value ? null : { passwordsDoNotMatch: true };
    }

    return null;
  }

  static noSpecialCharacters(c: AbstractControl, allowedChars?: string[]) {
    const allowedCharsJoined = allowedChars?.join('\\') || '';
    const noSpecialCharactersRegexPattern = '^[{{allowedCharacters}}\\d\\sA-Za-zÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]*$'
      .replace('{{allowedCharacters}}', allowedCharsJoined);
    const noSpecialCharactersRegex = new RegExp(noSpecialCharactersRegexPattern);

    if (!noSpecialCharactersRegex.test(c.value)) {
      return { foundSpecialCharacters: true };
    }
    return null;
  }

  static onlyPositiveNumbers(c: AbstractControl) {
    if (c.value === null || c.value === '') {
      // Allow empty values
      return null;
    }
    const onlyPositiveNumbersRegex = /^[0-9]*$/;
    if (!onlyPositiveNumbersRegex.test(c.value)) {
      return { notAPositiveNumber: true };
    }
    return null;
  }

  static endDateAfterStartDateValidator(startDateFieldName: string, endDateFieldName: string): (formGroup: FormGroup) => any {
    return (formGroup: FormGroup) => {
      const startDate = formGroup.get(startDateFieldName).value;
      const endDate = formGroup.get(endDateFieldName).value;
      if (startDate == null || endDate == null) {
        return null;
      }

      if (startDate && endDate) {
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        if (startDateObj > endDateObj) {
          return { endDateBeforeStartDate: true };
        }
      }

      return null;
    };
  }


}

export const validationRegex = {
  email: /^[_A-Za-z0-9-+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/
};

