import { Action, createReducer, on } from '@ngrx/store';
import * as LanguageActions from './language.actions';
import { Language } from '../../interfaces/language';

export const languageFeatureKey = 'language';

export interface State {
  languages: Language[];
  selectedLanguageLocale: string;
}

export const initialState: State = {
  languages: [],
  selectedLanguageLocale: null
};

const languageReducer = createReducer(
  initialState,

  on(LanguageActions.loadLanguages, state => state),
  on(LanguageActions.loadLanguagesSuccess, (state, { languages }) => loadLanguagesSuccessHandler(state, languages)),
  on(LanguageActions.loadSelectedLanguageLocaleSuccess, (state, { locale }) => loadSelectedLanguageLocaleSuccessHandler(state, locale))

);

export function reducer(state: State | undefined, action: Action) {
  return languageReducer(state, action);
}

function loadLanguagesSuccessHandler(state: State, languages: Language[]): State {
  return { ...state, languages };
}

function loadSelectedLanguageLocaleSuccessHandler(state: State, locale: string): State {
  return { ...state, selectedLanguageLocale: locale };
}
