import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MenubarModule } from 'primeng/menubar';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { DisplayNameComponent } from './header/display-name/display-name.component';
import { QuickTourComponent } from './quick-tour/quick-tour.component';
import { JoyrideModule } from 'ngx-joyride';


@NgModule({
  declarations: [HeaderComponent, HomeComponent, FooterComponent, DisplayNameComponent, QuickTourComponent],
  exports: [
    HeaderComponent,
    MenubarModule,
    QuickTourComponent
  ],
  imports: [
    CommonModule,
    MenubarModule,
    TranslateModule,
    DropdownModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    ReactiveFormsModule,
    SharedModule,
    JoyrideModule.forChild()
  ]
})
export class CoreModule {
}

