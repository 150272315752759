import { createAction, props } from '@ngrx/store';
import {AppSettings} from '../../interfaces/app-settings';

export const loadAppSettings = createAction(
  '[AppSettings] Load App Settings'
);

export const loadAppSettingsSuccess = createAction(
  '[AppSettings] Load App Settings Success',
  props<{ appSettings: AppSettings }>()
);

export const updateAppSettings = createAction(
  '[AppSettings] Update App Settings',
  props<{ newAppSettings: any }>()
);

export const updateAppSettingsSuccess = createAction(
  '[AppSettings] Update App Settings Success'
);




