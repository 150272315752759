import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-background',
  templateUrl: './home-background.component.html',
  styleUrls: ['./home-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeBackgroundComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  playGoatSound(): void {
    const audio = new Audio();
    audio.src = 'assets/audio/goat-sound.mp3';
    audio.load();
    audio.play();
  }

}
