import { createAction, props } from '@ngrx/store';
import { UserData } from '../../interfaces/user-data';

export const loadUserData = createAction(
  '[UserData] Load User Data'
);

export const loadUserDataSuccess = createAction(
  '[UserData] Load User Data Success',
  props<{ userData: UserData }>()
);

export const clearUserData = createAction(
  '[UserData] Clear User Data'
);

export const loadUserSettings = createAction(
  '[UserData] Load User Settings'
);

export const loadUserSettingsSuccess = createAction(
  '[UserData] Load User Settings Success',
  props<{ settings: any }>()
)











