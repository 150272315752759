import { Injectable, OnDestroy } from '@angular/core';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PaginationResponse } from '../interfaces/pagination-response';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as NotificationsSelectors from '../store/notifications/notifications.selectors';
import { Notification } from '../interfaces/notification';
import * as fromApp from '../store';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService implements OnDestroy {
  private notifications: BehaviorSubject<any> = new BehaviorSubject([]);
  private destroyed$ = new Subject();

  constructor(private apiSvc: ApiService, private store: Store<fromApp.AppState>) {
    this.subToNotifications();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  notifications$(): Observable<Notification[]> {
    return this.notifications.asObservable();
  }

  getNotifications(pagination): Observable<PaginationResponse> {
    return this.apiSvc.post('/user/feed', pagination);
  }

  subToNotifications(): void {
    // if we don't sub (and use notifications subject) and just pipe notifications directly
    // trough async in html the content is jumping on new notifications load when scrolling
    // same reference to notifications must be used
    this.store
      .pipe(
        takeUntil(this.destroyed$),
        select(NotificationsSelectors.selectNotifications)
      ).subscribe((notifications: Notification[]) => {
      let sheep = 0;
      let sheepNotifications = [];

      // clear subject when user logs out and state is cleared
      if (notifications.length === 0) {
        this.notifications.next([]);
        return;
      }

      // add sheep to notification
      if (notifications.length) {
        notifications.forEach(notification => {
          sheep = this.getNumberOfSheepFromMessage(notification.text);
          let message = this.adjustNotificationText(notification.text);

          if (sheep > 0) {
            sheepNotifications.push({
              ...notification,
              sheep: sheep,
              firstPart: message.firstPart,
              lastPart: message.lastPart
            })
          } else {
            sheepNotifications.push({
              ...notification,
              sheep: sheep,
            })
          }
        })
      }

      if (this.notifications.getValue().length === 0) {
        this.notifications.next(sheepNotifications);
      }
      else {
        let notifications = this.notifications.getValue();

        // push new notifications
        for (let i = sheepNotifications.length - (sheepNotifications.length - notifications.length); i < sheepNotifications.length; i++) {
          notifications.push(sheepNotifications[i]);
        }

        this.notifications.next(notifications);
      }
    });
  }

  getNumberOfSheepFromMessage(message): number {
    const idx = message.indexOf(' points:');
    let numberOfSheep = '';

    if (idx > -1) {
      for (let i = idx - 1; i > 0; i--) {
        if (message[i] === ' ') {
          break;
        }

        numberOfSheep = numberOfSheep + message[i];
      }
    }

    // reverse string with help of reverse and convert to number
    return numberOfSheep ? +numberOfSheep.split('').reverse().join('') : 0;
  }

  adjustNotificationText(message): any {
    const idx = message.indexOf(' points:');
    let idxFirstPart = - 1;
    let idxLastPart = -1;


    if (idx > -1) {
      idxLastPart = idx + 7;
      for (let i = idx - 1; i > 0; i--) {
        if (message[i] === ' ') {
          idxFirstPart = i + 1;
          break;
        }
      }

      return {
        firstPart: message.substring(0, idxFirstPart),
        lastPart: message.substring(idxLastPart+1, message.length ).replaceAll("\"", '')
      };
    }

    return message;
  }
}
