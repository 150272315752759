import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as AuthSelectors from '../store/auth/auth.selectors';
import { map } from 'rxjs/operators';
import { UserRoles } from '../constants/user-roles';

export const RoleGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  return inject(Store)
    .pipe(
      select(AuthSelectors.selectUserRoles),
      map((userRoles: UserRoles[]) => {
        if (!userRoles.find(userRole => route.data.roles.find(role => userRole === role))) {
          router.navigateByUrl('/home');
          return false;
        }

        return true;
      })
    );
};
