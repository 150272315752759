<div class="dashboard-panel h-100">
  <div class="header flex-row justify-content-between">
    <h1 class="subtitle">{{ 'NOTIFICATIONS.TITLE' | translate }}</h1>
    <button pButton [label]="'NOTIFICATIONS.CREATE' | translate" (click)="openCreateDialog()"></button>
  </div>
  <div class="ui-g h-100">
    <div class="ui-g-12" *ngIf="(notificationList$ | async) as list">
      <ng-container *ngIf="list?.totalElements > 0; else noResults">
        <p-table [value]="list.content" [lazyLoadOnInit]="false" [lazy]="true" [rows]="10"
                 (onLazyLoad)="onLazyLoad($event)" [totalRecords]="list.totalElements"
                 [sortOrder]="-1" [paginator]="true" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'NOTIFICATIONS.LIST.CREATED_BY' | translate }}</th>
            <th pSortableColumn="date_created">{{ 'NOTIFICATIONS.LIST.DATE_CREATED' | translate }} <p-sortIcon field="date_created"></p-sortIcon></th>
            <th>{{ 'NOTIFICATIONS.LIST.TITLE' | translate }}</th>
            <th width="40%">{{ 'NOTIFICATIONS.LIST.MESSAGE' | translate }}</th>
            <th pSortableColumn="role_name">{{ 'NOTIFICATIONS.LIST.SENT_TO' | translate }} <p-sortIcon field="role_name"></p-sortIcon></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-notification>
          <tr>
            <td>{{ notification.notificationAuthor }}</td>
            <td>{{ notification.dateCreated | date: 'shortDate' }}</td>
            <td>{{ notification.title }}</td>
            <td>
              <div class="message" [ngClass]="{'collapsed': textCollapsed}" truncatedText>
                {{ notification.message }}
              </div>
              <div class="toggle-text" (click)="toggleText()">
                {{ (textCollapsed ? 'NOTIFICATIONS.SEE_MORE' : 'NOTIFICATIONS.SEE_LESS') | translate }}
              </div>
            </td>
            <td>{{ notification.roleName | titlecase}}</td>
          </tr>
        </ng-template>
      </p-table>
      </ng-container>

      <ng-template #noResults>
        <span>{{ 'NOTIFICATIONS.NO_NOTIFICATIONS' | translate }}</span>
      </ng-template>
    </div>
  </div>
</div>
<app-modal-create-notification [visible]="dialogVisible"
                               (closed)="onCreateModalClose()"
                               (confirm)="createNotification($event)">
</app-modal-create-notification>



