import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromLanguage from './language/language.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromAppSettings from './app-settings/app-settings.reducer';
import * as fromNotifications from './notifications/notifications.reducer';
import * as fromUserData from './user-data/user-data.reducer';
import { LanguageEffects } from './language/language.effects';
import { AuthEffects } from './auth/auth.effects';
import { AppSettingsEffects } from './app-settings/app-settings.effects';
import { NotificationsEffects } from './notifications/notifications.effects';
import { UserDataEffects } from './user-data/user-data.effects';

export const storeFeatureKey = 'store';

export interface AppState {
  auth: fromAuth.State;
  language: fromLanguage.State;
  appSettings: fromAppSettings.State;
  notifications: fromNotifications.State;
  userData: fromUserData.State;
}

export const reducers: ActionReducerMap<AppState> = {
  language: fromLanguage.reducer,
  auth: fromAuth.reducer,
  appSettings: fromAppSettings.reducer,
  notifications: fromNotifications.reducer,
  userData: fromUserData.reducer
};


export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

export const effects: any[] = [
  AuthEffects,
  LanguageEffects,
  AppSettingsEffects,
  NotificationsEffects,
  UserDataEffects
];
