import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUserData from './user-data.reducer';

export const selectUserDataState = createFeatureSelector<fromUserData.State>(
  fromUserData.userDataFeatureKey
);

export const selectSettings = createSelector(
  selectUserDataState,
  (state: fromUserData.State) => state.settings
);

export const selectSettingIsMultiAlloc = createSelector(
  selectSettings,
  (settings: any) => settings?.find(perm => perm.code === 'multiple-allocations')?.value
)

export const selectSettingAllowEditingAlloc = createSelector(
  selectSettings,
  (settings: any) => settings?.find(perm => perm.code === 'allow-editing-allocations')?.value
)

export const selectSettingAllowEditingAllocMin = createSelector(
  selectSettings,
  (settings: any) => settings?.find(perm => perm.code === 'allow-editing-allocations-min')?.value
)

export const selectSettingMinChars = createSelector(
  selectSettings,
  (settings: any) => settings?.find(perm => perm.code === 'reason-min-chars')?.value
)

export const selectSettingMinWords = createSelector(
  selectSettings,
  (settings: any) => settings?.find(perm => perm.code === 'reason-min-words')?.value
)

export const selectUserData = createSelector(
  selectUserDataState,
  (state: fromUserData.State) => state
);
