import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { UserRoles } from './shared/constants/user-roles';
import { RoleGuard } from './shared/guards/role.guard';
import { NotificationsListComponent } from './shared/components/notifications-create/notifications-list.component';


const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [UserRoles.ADMIN]
    }
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'merit',
    loadChildren: () => import('./merit/merit.module').then(m => m.MeritModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsListComponent,
    data: {
      roles: [
        UserRoles.OWNER,
        UserRoles.MANAGER
      ]
    },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

