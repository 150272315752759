import * as fromLanguage from './language.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectFeature = createFeatureSelector<fromLanguage.State>(fromLanguage.languageFeatureKey);

export const selectLanguages = createSelector(
  selectFeature,
  (state: fromLanguage.State) => state.languages
);

export const selectSelectedLanguageLocale = createSelector(
  selectFeature,
  (state: fromLanguage.State) => state.selectedLanguageLocale
);

export const selectSelectedLanguage = createSelector(
  selectFeature,
  (state: fromLanguage.State) => state.languages.find(language => language.locale === state.selectedLanguageLocale)
);
