import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {catchError, concatMap, map} from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import * as LanguageActions from './language.actions';
import {LanguageService} from '../../services/language.service';
import {Language} from '../../interfaces/language';
import {TranslateService} from '@ngx-translate/core';
import {LanguageConstants} from '../../constants/language-constants';
import {Action} from '@ngrx/store';


@Injectable()
export class LanguageEffects {


  constructor(private actions$: Actions, private languageSvc: LanguageService,
              private translateSvc: TranslateService) {}

  loadLanguages$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActions.loadLanguages),
    concatMap(() => this.languageSvc.getLanguages()
      .pipe(
        map((languages: Language[]) => LanguageActions.loadLanguagesSuccess({ languages })),
        catchError(() => EMPTY)
      )
    )
  ));

  loadSelectedLanguageLocale$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActions.loadSelectedLanguageLocale),
    map((action) => {
      localStorage.setItem('languageLocale', action.locale);
      this.translateSvc.use(action.locale);
      return LanguageActions.loadSelectedLanguageLocaleSuccess({ locale: action.locale });
    })
  ));

  ngrxOnInitEffects(): Action {
    const locale = localStorage.getItem('languageLocale') || LanguageConstants.DEFAULT;
    this.translateSvc.setDefaultLang(LanguageConstants.DEFAULT);
    return LanguageActions.loadSelectedLanguageLocale({ locale });
  }

}
