import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserRoles } from '../../../shared/constants/user-roles';
import { select, Store } from '@ngrx/store';
import * as AuthSelectors from '../../../shared/store/auth/auth.selectors';
import { map, take } from 'rxjs/operators';
import * as fromApp from '../../../shared/store';

@Injectable({
  providedIn: 'root'
})
export class QuickTourService {

  private startQuickTour: Subject<string[]> = new Subject();
  startQuickTour$: Observable<string[]> = this.startQuickTour.asObservable();

  constructor(private store: Store<fromApp.AppState>) {
  }

  startTour(userRoles: string[]): void {
    this.startQuickTour.next(userRoles);
  }

  startTourForAuthenticatedUser(): void {
    this.store
      .pipe(
        select(AuthSelectors.selectUserRoles),
        take(1),
        map((userRoles: UserRoles[]) => {
          this.startTour(userRoles);
        })
      )
      .subscribe();
  }
}
