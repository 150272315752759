import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserData } from '../../../shared/interfaces/user-data';

@Component({
  selector: 'app-display-name',
  templateUrl: './display-name.component.html',
  styleUrls: ['./display-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayNameComponent {
  @Input() userData: UserData;
}
