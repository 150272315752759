<p-menubar [model]="menuItems$ | async" [styleClass]="!(isLoggedIn$ | async) ? 'logged-out' : ''">
  <ng-template pTemplate="start">
    <a class="navbar-brand" [routerLink]="'/merit'">
      <img src="assets/img/meerite-logo.svg" height="34" alt="meerite">
    </a>  </ng-template>
  <ng-template pTemplate="end">
    <ul class="d-flex align-items-center">
      <li>
        <ng-container *ngIf="userData$ | async as userData">
          <div *ngIf="userData.organizationName" class="user p-mr-2">
            <app-display-name [userData]="userData"></app-display-name>
          </div>
        </ng-container>
      </li>
      <li class="p-mr-2">
        <i (click)="showQuickTour()" class="pi pi-question-circle"
           pTooltip="{{ 'HEADER.LINK.QUICK_TOUR' | translate }}" tooltipPosition="bottom" showDelay="500"
           hideDelay="500"></i>
      </li>
      <li>
        <a *ngIf="isLoggedIn$ | async"
           class="p-menuitem-signout"
           (click)="logout()">
          <span class="pp-menuitem-text">
            <i class="fa fa-sign-out"></i>
          </span>
        </a>
      </li>
    </ul>
  </ng-template>
</p-menubar>

