import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import * as UserDataActions from './user-data.actions';
import * as AuthActions from '../auth/auth.actions';
import * as AuthSelectors from '../auth/auth.selectors';
import { UserService } from '../../services/user.service';
import { UserData } from '../../interfaces/user-data';
import { Action, select, Store } from '@ngrx/store';
import * as fromApp from '../../store';


@Injectable()
export class UserDataEffects {

  constructor(private actions$: Actions, private userSvc: UserService,
              private store: Store<fromApp.AppState>) {}

  loadUserSettings$ = createEffect(() => this.actions$.pipe(
    ofType(UserDataActions.loadUserData),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectIsLoggedIn))),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectUserRoles))),
    switchMap((([[action, loggedIn], userRoles]) => {
      if (loggedIn) {
        return this.userSvc.getUserSettings()
          .pipe(
            map((settings: any) => UserDataActions.loadUserSettingsSuccess({ settings })),
            catchError(() => EMPTY)
          );
      }
      return EMPTY;
    }))
  ));

  loadUserData$ = createEffect(() => this.actions$.pipe(
    ofType(UserDataActions.loadUserData),
    withLatestFrom(this.store.pipe(select(AuthSelectors.selectIsLoggedIn))),
    switchMap(([action, loggedIn]) => {
      if (loggedIn) {
        return this.userSvc.getUserData()
          .pipe(
            map((userData: UserData) => UserDataActions.loadUserDataSuccess({userData})),
            catchError(() => EMPTY)
          );
      }
      return EMPTY;
    })
  ));

  loadUserDataOnLogin$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.loginSuccess),
    map(() => UserDataActions.loadUserData())
  ));

  clearUserDataOnLogout$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.logout),
    map(() => UserDataActions.clearUserData())
  ));

  ngrxOnInitEffects(): Action {
    return UserDataActions.loadUserData();
  }

}
