import { Injectable } from '@angular/core';
import { Language } from '../interfaces/language';
import { Observable, of } from 'rxjs';
import { LanguageConstants } from '../constants/language-constants';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages: Language[] = [
    { name: 'English', flag: 'flag-england', locale: LanguageConstants.ENGLISH },
    // { name: 'German', flag: 'flag-germany', locale: LanguageConstants.GERMAN }
  ];

  constructor() { }

  getLanguages(): Observable<Language[]> {
    return of(this.languages);
  }
}
