<ng-container *ngIf="headerType$ | async as headerType">
  <ng-container [ngSwitch]="headerType">
    <app-header-admin *ngSwitchCase="headerTypes.Admin"></app-header-admin>
    <app-header *ngSwitchCase="headerTypes.Regular"></app-header>
  </ng-container>

  <div class="main-content" [ngClass]="{
  'admin': headerType === headerTypes.Admin,
  'landing': headerType === headerTypes.Landing }">
    <ng-scrollbar>
      <router-outlet></router-outlet>
    </ng-scrollbar>

    <!-- Display static fixed animation background at the bottom of page -->
    <ng-container *ngIf="bottomBackgroundAnimationType$ | async as bottomBackgroundAnimationType">
      <ng-container [ngSwitch]="bottomBackgroundAnimationType">
        <ng-container *ngSwitchCase="bottomBackgroundAnimationTypes.Home">
          <app-home-background></app-home-background>
        </ng-container>
        <ng-container *ngSwitchCase="bottomBackgroundAnimationTypes.Primary">
          <app-primary-background></app-primary-background>
        </ng-container>
        <ng-container *ngSwitchCase="bottomBackgroundAnimationTypes.Secondary">
          <app-secondary-background></app-secondary-background>
        </ng-container>
        <ng-container *ngSwitchCase="bottomBackgroundAnimationTypes.Dashboard">
          <app-dashboard-background></app-dashboard-background>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
<p-toast></p-toast>

<app-quick-tour></app-quick-tour>

