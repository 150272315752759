import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { validateForm } from '../../shared/utils/validation';
import { FormGroup } from '@angular/forms';
import { InviteListService } from '../../shared/services/invite-list.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  appVersion: string = environment.appVersion;

  inviteListForm: FormGroup = this.inviteListSvc.getInviteListForm();

  constructor(private inviteListSvc: InviteListService) { }

  submitForm() {
    if (!this.inviteListForm.get('email').value) {
      return;
    }

    if (!this.inviteListForm.valid) {
      validateForm(this.inviteListForm);
      return;
    }

    this.inviteListSvc.addEmailToInviteList(this.inviteListForm.get('email').value);
  }
}
