import { createAction, props } from '@ngrx/store';
import { OauthToken } from '../../interfaces/oauth-token';

export const login = createAction(
  '[Auth] Login',
  props<{ username: string, password: string }>()
);

export const loginSuccess = createAction(
  '[Auth] Login Success',
  props<{ token: OauthToken }>()
);

export const loginFailed = createAction(
  '[Auth] Login Failed',
  props<{ errorMessage: string }>()
);

export const logout = createAction(
  '[Auth] Logout'
);

export const loginRefresh = createAction(
  '[Auth] Login Refresh',
  props<{ token: OauthToken }>()
);

export const register = createAction(
  '[Auth] Register',
  props<{ registerFormData: any, captchaResponse: string }>()
);

export const registerPending = createAction(
  '[Auth] Register Request Pending'
);

export const registerSuccess = createAction(
  '[Auth] Register Success'
);

export const registerFail = createAction(
  '[Auth] Register Fail'
);

export const clearRegistrationData = createAction(
  '[Auth] Clear Registration Data'
);

export const refreshToken = createAction(
  '[Auth] Refresh Token'
);

export const refreshTokenSuccess = createAction(
  '[Auth] Refresh Token Success',
  props<{ token: OauthToken }>()
);



