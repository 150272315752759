import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { effects, metaReducers, reducers } from './shared/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import { AppService } from './shared/services/app.service';
import { RefreshTokenInterceptor } from './shared/interceptors/refresh-token.interceptor';
import { AdminModule } from './admin/admin.module';
import { registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SharedModule } from './shared/shared.module';
import { JoyrideModule } from 'ngx-joyride';

registerLocaleData(localeSl);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    CoreModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictStateSerializability: true,
        strictActionSerializability: true,
      }
    }),
    EffectsModule.forRoot(effects),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production, connectInZone: true}),
    AdminModule,
    NgScrollbarModule,
    ToastModule,
    SharedModule,
    JoyrideModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AppService,
    {
      provide: APP_INITIALIZER,
      useFactory: (appService: AppService) => () => appService.load(),
      multi: true,
      deps: [AppService]
    },
    {provide: LOCALE_ID, useValue: 'sl'},
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

