import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiSvc: ApiService) { }

  login(credentials) {
    return this.apiSvc.post('/login', credentials);
  }

  refreshToken(data) {
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };

    return this.apiSvc.post('/oauth/access_token', data, options);
  }

  register(userData, captchaResponse) {
    const options = {
      params: new HttpParams().set('g-recaptcha-response', captchaResponse)
    };

    return this.apiSvc.post('/register', userData, options);
  }
}
