import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ModalComponent } from '../../modals/modal.component';
import { validateForm } from '../../../utils/validation';
import { CreateNotificationService } from '../../../services/create-notification.service';
import { UserRoles } from '../../../constants/user-roles';
import { DialogModule } from 'primeng/dialog';
import { AsyncPipe, JsonPipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-modal-create-notification',
  templateUrl: './modal-create-notification.component.html',
  styleUrls: ['./modal-create-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    AsyncPipe,
    TranslateModule,
    MultiSelectModule,
    JsonPipe,
    NgIf,
    InputTextareaModule,
    InputTextModule
  ],
  standalone: true
})
export class ModalCreateNotificationComponent extends ModalComponent implements OnInit {

  notificationForm: FormGroup;

  private notificationSvc = inject(CreateNotificationService);
  private formBuilder = inject(FormBuilder);
  userRoles$: Observable<UserRoles[]> = this.notificationSvc.roles$();

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.notificationForm = this.formBuilder.group({
      message: [null, [Validators.required]],
      title: [null, [Validators.required]],
      roles: [null, [Validators.required]]
    });
  }

  onConfirm() {
    if (!this.notificationForm.valid) {
      validateForm(this.notificationForm);
      return;
    }

    const notification = {
      roleNames: this.notificationForm.get('roles').value.map(item => item.userRole),
      message: this.notificationForm.get('message').value,
      title: this.notificationForm.get('title').value
    };
    this.initializeForm();
    this.confirm.next(notification);
  }

  onCancel(): void {
    this.visible = false;
    this.initializeForm();
    this.closed.next(null);
  }
}
