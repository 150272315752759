import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromAppSettings from './app-settings.reducer';

export const selectFeature = createFeatureSelector<fromAppSettings.State>(fromAppSettings.appSettingsFeatureKey);

export const selectAppSettingsLoading = createSelector(
  selectFeature,
  (state: fromAppSettings.State) => state.appSettingsLoading
);

export const selectRegistrationActive = createSelector(
  selectFeature,
  (state: fromAppSettings.State) => state.registrationActive
);
