import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-background',
  templateUrl: './dashboard-background.component.html',
  styleUrls: ['./dashboard-background.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardBackgroundComponent {

  playGoatSound(): void {
    const audio = new Audio();
    audio.src = 'assets/audio/goat-sound.mp3';
    audio.load();
    audio.play();
  }

}
