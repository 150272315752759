import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';
import { OauthToken } from '../../interfaces/oauth-token';
import { AccessTokenData } from '../../interfaces/access-token-data';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RequestStatus } from '../../../merit/shared/enums/request-status.enum';

const jwtHelperService = new JwtHelperService();

export const authFeatureKey = 'auth';

export interface State {
  token: OauthToken;
  errorMessage: string;
  loggingIn: boolean;
  isLoggedIn: boolean;
  accessTokenData: AccessTokenData;
  registrationRequestStatus: RequestStatus;
  refreshToken: string;
  refreshingToken: boolean;
  tokenRefreshed: boolean;
}

export const initialState: State = {
  token: null,
  errorMessage: null,
  loggingIn: false,
  isLoggedIn: false,
  accessTokenData: null,
  registrationRequestStatus: null,
  refreshToken: null,
  refreshingToken: false,
  tokenRefreshed: false
};

const authReducer = createReducer(
  initialState,

  on(AuthActions.login, (state) => loginHandler(state)),
  on(AuthActions.loginSuccess, (state, { token }) => loginSuccessHandler(state, token)),
  on(AuthActions.loginFailed, (state, { errorMessage }) => loginFailedHandler(state, errorMessage)),
  on(AuthActions.loginRefresh, (state, { token }) => loginRefreshHandler(state, token)),
  on(AuthActions.logout, () => logoutHandler()),
  on(AuthActions.registerPending, (state) => registerPendingHandler(state)),
  on(AuthActions.registerSuccess, (state) => registerSuccessHandler(state)),
  on(AuthActions.registerFail, (state) => registerFailHandler(state)),
  on(AuthActions.clearRegistrationData, (state) => clearRegistrationDataHandler(state)),
  on(AuthActions.refreshToken, (state) => refreshTokenHandler(state)),
  on(AuthActions.refreshTokenSuccess, (state, { token }) => refreshTokenSuccessHandler(state, token))

);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

function loginHandler(state: State): State {
  return { ...state, loggingIn: true };
}

function logoutHandler(): State {
  return  { ...initialState };
}

function loginSuccessHandler(state: State, token: OauthToken): State {
  return {
    ...state,
    loggingIn: false,
    isLoggedIn: true,
    token,
    accessTokenData: jwtHelperService.decodeToken(token.access_token),
    refreshToken: token.refresh_token
  };
}

function loginFailedHandler(state: State, errorMessage: string): State {
  return { ...state, loggingIn: false, errorMessage };
}

function loginRefreshHandler(state: State, token: OauthToken): State {
  if (token) {
    return {
      ...state,
      isLoggedIn: true,
      token,
      accessTokenData: jwtHelperService.decodeToken(token.access_token),
      refreshToken: token.refresh_token
    };
  }

  return state;
}

function registerPendingHandler(state: State): State {
  return {...state, registrationRequestStatus: RequestStatus.Pending};
}

function registerSuccessHandler(state: State): State {
  return {...state, registrationRequestStatus: RequestStatus.Success};
}

function registerFailHandler(state: State): State {
  return {...state, registrationRequestStatus: RequestStatus.Failed};
}

function clearRegistrationDataHandler(state: State): State {
  return {...state, registrationRequestStatus: RequestStatus.NotStarted};
}

function refreshTokenHandler(state: State): State {
  return {...state, refreshingToken: true, tokenRefreshed: false };
}

function refreshTokenSuccessHandler(state: State, token: OauthToken): State {
  return {
    ...state,
    refreshingToken: false,
    tokenRefreshed: true,
    token,
    accessTokenData: jwtHelperService.decodeToken(token.access_token),
    refreshToken: token.refresh_token
  };
}
