import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MerriteMessageService } from '../../core/shared/services/merrite-message.service';
import { CustomValidators } from '../utils/validation';

@Injectable({
  providedIn: 'root'
})
export class InviteListService {
  private inviteListForm: FormGroup;

  constructor(private apiSvc: ApiService, private messageSvc: MerriteMessageService,
              private formBuilder: FormBuilder) {
    this.initializeForm();

  }

  initializeForm() {
    this.inviteListForm = this.formBuilder.group({
      email: ['', [CustomValidators.validateEmail]]
    });
  }

  getInviteListForm(): FormGroup {
    return this.inviteListForm;
  }

  addEmailToInviteList(email) {
    this.apiSvc.post('/invite/create', {email})
      .subscribe(() => {
        this.messageSvc.publish('EMAIL_ADDED_TO_INVITE_LIST');
        this.inviteListForm.reset();
      });
  }

  getInviteList() {
    return this.apiSvc.get('/invite');
  }
}
