import { Action, createReducer, on } from '@ngrx/store';
import * as AppSettingsActions from './app-settings.actions';
import {AppSettings} from '../../interfaces/app-settings';

export const appSettingsFeatureKey = 'appSettings';

export interface State {
  registrationActive: boolean;
  appSettingsLoading: boolean;
}

export const initialState: State = {
  registrationActive: false,
  appSettingsLoading: false
};

const appSettingsReducer = createReducer(
  initialState,

  on(AppSettingsActions.loadAppSettings, state => loadAppSettingsHandler(state)),
  on(AppSettingsActions.loadAppSettingsSuccess, (state, { appSettings }) => loadAppSettingsSuccessHandler(state, appSettings))

);

export function reducer(state: State | undefined, action: Action) {
  return appSettingsReducer(state, action);
}

export function loadAppSettingsHandler(state: State): State {
 return { ...state, appSettingsLoading: true };
}

export function loadAppSettingsSuccessHandler(state: State, appSettings: AppSettings): State {
  return { ...state, registrationActive: appSettings.registrationActive, appSettingsLoading: false };
}
