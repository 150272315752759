import { Component } from '@angular/core';
import { PageContentTypeService } from './core/shared/services/page-content-type.service';
import { Observable } from 'rxjs';
import { HeaderType } from './core/shared/enums/header-type';
import { BackgroundType } from './core/shared/enums/background-type';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  headerTypes = HeaderType;
  headerType$: Observable<HeaderType> = this.pageContentTypeSvc.headerType$;

  bottomBackgroundAnimationTypes = BackgroundType;
  bottomBackgroundAnimationType$: Observable<BackgroundType> = this.pageContentTypeSvc.bottomBackgroundAnimationType$;

  constructor(private pageContentTypeSvc: PageContentTypeService) {}
}
