<joyrideStep joyrideStep="welcome"
             title="{{ 'ONBOARDING.WELCOME.TITLE' | translate }}"
             text="{{ 'ONBOARDING.WELCOME.TEXT' | translate }}"
             stepPosition="center"/>
<!--OWNER-->
<joyrideStep joyrideStep="owner-organization-settings"
             title="{{ 'ONBOARDING.ORGANIZATION_SETTINGS.TITLE' | translate }}"
             text="{{ 'ONBOARDING.ORGANIZATION_SETTINGS.OWNER.TEXT' | translate }}"
             stepPosition="center"/>
<joyrideStep joyrideStep="owner-periods"
             title="{{ 'ONBOARDING.PERIODS.TITLE' | translate }}"
             text="{{ 'ONBOARDING.PERIODS.TEXT' | translate }}"
             stepPosition="center"/>
<joyrideStep joyrideStep="owner-members"
             title="{{ 'ONBOARDING.MEMBERS.TITLE' | translate }}"
             text="{{ 'ONBOARDING.MEMBERS.OWNER.TEXT' | translate }}"
             stepPosition="center"/>
<joyrideStep joyrideStep="owner-end"
             title="{{ 'ONBOARDING.END.TITLE' | translate }}"
             [stepContent]="ownerJoyRideEndContent"
             stepPosition="center"/>

<!--USER-->
<joyrideStep joyrideStep="user-feedback"
             title="{{ 'ONBOARDING.GIVE_FEEDBACK.TITLE' | translate }}"
             text="{{ 'ONBOARDING.GIVE_FEEDBACK.USER.TEXT' | translate }}"
             stepPosition="center"/>
<joyrideStep joyrideStep="user-friendly-advice"
             title="{{ 'ONBOARDING.FRIENDLY_ADVICE.TITLE' | translate }}"
             text="{{ 'ONBOARDING.FRIENDLY_ADVICE.TEXT' | translate }}"
             stepPosition="center"/>
<joyrideStep joyrideStep="user-end"
             title="{{ 'ONBOARDING.END.TITLE' | translate }}"
             [stepContent]="userJoyRideEndContent"
             stepPosition="center"/>

<joyrideStep joyrideStep="manager-organization-settings" title="Configure settings"
             text="Adjust organization settings to best suit organizational needs." stepPosition="center"></joyrideStep>
<joyrideStep joyrideStep="manager-feedback" title="Give feedback"
             text="You can also give feedback yourself. So pick individuals to give feedback to."
             stepPosition="center"></joyrideStep>
<joyrideStep joyrideStep="manager-members" title="Add members" text="Invite members to join the organization."
             stepPosition="center"></joyrideStep>

<ng-template #ownerJoyRideEndContent>
  <img alt="owner-joyride-end-meme" src="../assets/img/owner-onboarding-thank-you-gif.gif" height="480px" width="480px">
</ng-template>
<ng-template #userJoyRideEndContent>
  <img alt="user-joyride-end-meme" src="../assets/img/user-onboarding-thank-you-gif.gif" height="360px" width="480px">
</ng-template>
