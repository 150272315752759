import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectLanguageComponent } from './components/select-language/select-language.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TranslateModule } from '@ngx-translate/core';
import { InputTextModule } from 'primeng/inputtext';
import {
  PrimaryBackgroundComponent
} from './components/goat-backgrounds/primary-background/primary-background.component';
import {
  SecondaryBackgroundComponent
} from './components/goat-backgrounds/secondary-background/secondary-background.component';
import { HomeBackgroundComponent } from './components/goat-backgrounds/home-background/home-background.component';
import {
  DashboardBackgroundComponent
} from './components/goat-backgrounds/dashboard-background/dashboard-background.component';

@NgModule({
  declarations: [
    SelectLanguageComponent,
    PrimaryBackgroundComponent,
    SecondaryBackgroundComponent,
    HomeBackgroundComponent,
    DashboardBackgroundComponent
  ],
  exports: [
    SelectLanguageComponent,
    PrimaryBackgroundComponent,
    SecondaryBackgroundComponent,
    HomeBackgroundComponent,
    DashboardBackgroundComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    InputTextareaModule,
    TranslateModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule
  ]
})
export class SharedModule { }
