import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AppSettingsComponent } from './app-settings/app-settings.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { InviteListComponent } from './invite-list/invite-list.component';
import { TableModule } from 'primeng/table';
import { HeaderAdminComponent } from './header/header.component';
import { MenubarModule } from 'primeng/menubar';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [DashboardComponent, AppSettingsComponent, InviteListComponent, HeaderAdminComponent],
  exports: [
    HeaderAdminComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    InputSwitchModule,
    ButtonModule,
    TranslateModule,
    FormsModule,
    TableModule,
    MenubarModule,
    SharedModule
  ]
})
export class AdminModule { }
