import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MerriteMessageService } from '../../core/shared/services/merrite-message.service';

@Injectable()
export abstract class BaseService {

  protected httpClient: HttpClient;
  protected translateSvc: TranslateService;
  protected messageService: MerriteMessageService;
  protected path: string;

  protected constructor(injector: Injector) {
    this.httpClient = injector.get(HttpClient);
    this.translateSvc = injector.get(TranslateService);
    this.messageService = injector.get(MerriteMessageService);
  }

  /**
   * Headers are provided in core.interceptor
   */
  getUrl(endpoint: string): string{
    return `${this.path}${endpoint}`;
  }

  get<T>(endpoint: string, options = {}, displayError = true){
    return this.httpClient.get<T>(this.getUrl(endpoint), options)
      .pipe(
        catchError(err => this.handleError(err, displayError))
      );
  }

  post<T>(endpoint: string, body, options = {}, displayError = true): Observable<T>{
    return this.httpClient.post<T>(this.getUrl(endpoint), body, options)
      .pipe(
        catchError(err => this.handleError(err, displayError))
      );
  }

  put(endpoint: string, body, options = {}, displayError = true){
    return this.httpClient.put(this.getUrl(endpoint), body, options)
      .pipe(
        catchError(err => this.handleError(err, displayError))
      );
  }

  delete(endpoint: string, options = {}, displayError = true){
    return this.httpClient.delete(this.getUrl(endpoint), options)
      .pipe(
        catchError(err => this.handleError(err, displayError))
      );
  }


  handleError(response: any, displayError) {
    this.messageService.publish(response.error.code);

    return throwError(response.error);
  }
}

