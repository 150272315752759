import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromNotifications from '../notifications/notifications.reducer';

export const selectFeature = createFeatureSelector<fromNotifications.State>(fromNotifications.notificationsFeatureKey);

export const selectNotifications = createSelector(
  selectFeature,
  (state: fromNotifications.State) => state.notifications
);

// returns pagination for next page to load
export const selectPaginationNext = createSelector(
  selectFeature,
  (state: fromNotifications.State) => {
    return {
      page: state.page + 1,
      resultsPerPage: state.resultsPerPage,
      order: state.order,
      orderBy: state.orderBy,
      pagesAvailable: state.pagesAvailable
    };
  }
);

export const selectInitialLoad = createSelector(
  selectFeature,
  (state: fromNotifications.State) => state.initialLoad
)
