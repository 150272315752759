import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '../store';
import { select, Store } from '@ngrx/store';
import * as AuthActions from '../store/auth/auth.actions';
import * as AuthSelectors from '../store/auth/auth.selectors';

/***
 * This interceptor must be listed above the interceptor that adds headers to requests (in providers array)
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  isRefreshing = false;

  constructor(private store: Store<AppState>) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          return throwError(error);
        }

        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (request.url.includes('oauth') || request.url.includes('login')) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user
          if (request.url.includes('oauth')) {
            this.store.dispatch(AuthActions.logout());
          }

          return throwError(error);
        }

        if (!this.isRefreshing) {
          this.store.dispatch(AuthActions.refreshToken());
        }

        return this.store.pipe(
          select(AuthSelectors.refreshingToken),
          tap((tokenRefresh: AuthSelectors.TokenRefreshing) => this.isRefreshing = tokenRefresh.refreshing),
          filter((tokenRefresh: AuthSelectors.TokenRefreshing) => !tokenRefresh.refreshing),
          take(1),
          switchMap((tokenRefresh: AuthSelectors.TokenRefreshing) => {
            if (tokenRefresh.refreshed) {
              return next.handle(request);
            }
            else  {
              return throwError(error);
            }
          })
        );
      }));
  }
}
